export enum userType {
    Patient = 1,
    AccNum = 2,
    Lab = 3,
    Doctor = 4,
    Payer = 5,
    Contract = 6,
    Location = 7,
    ClientAdmin = 8,
    BranchManager = 11
}
