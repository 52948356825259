export class CustomLabelDTO {
  visit_Date_From_Search: string;
  visit_Date_To_Search: string;
  patient_No_Search: string;
  acc_No_From_Search: string;
  acc_No_To_Search: string;
  acc_No_Search: string;
  branch_Search: string;
  doctor_Search: string;
  location_Search: string;
  center_Search: string;
  payer_Search: string;
  contract_Search: string;
  patient_Name_Search: string;
  visit_Date_Grid: string;
  acc_No_Grid: string;
  patient_Name_Grid: string;
  branch_Grid: string;
  doctor_Grid: string;
  location_Grid: string;
  center_Grid: string;
  payer_Grid: string;
  contract_Grid: string;
  file_No_Grid: string;
  report_Name_Grid: string;
  report_Type_Grid: string;
  seen_search: string;

  result_Date_Grid: string;
  uRL_Grid: string;
  admin_Username_Login_Page: string;
  patient_ID_Login_Page: string;
  location_Username_Login_Page: string;
  doctor_Username_Login_Page: string;
  payer_Username_Login_Page: string;
  center_Username_Login_Page: string;
  branch_Username_Login_Page: string;
  accession_Login_Page: string;
  password_Login_Page: string;
  report_Link_Custom_Name: string;
  waiting_Review_Message: string;
}
