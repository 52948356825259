import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    {
      path: '',
     loadChildren: () => import('./modules/clientLogin/clientLogin.module').then(m => m.ClientLoginModule)
    },
    // {
    //     path: 'Login',
    //     loadChildren: () => import('./modules/clientLogin/clientLogin.module').then(m => m.ClientLoginModule)
    // },
    {
      path: 'login',
      loadChildren: () => import('./modules/clientLogin/clientLogin.module').then(m => m.ClientLoginModule)
  },
    {
        path: 'adminlogin',
        loadChildren: () => import('./modules/adminLogin/adminLogin.module').then(m => m.AdminLoginModule)
    },
  //   {
  //     path: 'adminLogin',
  //     loadChildren: () => import('./modules/adminLogin/adminLogin.module').then(m => m.AdminLoginModule)
  // },
  //   {
  //     path: 'Login/adminLogin',
  //     loadChildren: () => import('./modules/adminLogin/adminLogin.module').then(m => m.AdminLoginModule)
  // },
  {
    path: 'login/adminlogin',
    loadChildren: () => import('./modules/adminLogin/adminLogin.module').then(m => m.AdminLoginModule)
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
